
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import Util from '@/lib/util'
    import AbpBase from '@/lib/abpbase'
    import PageRequest from '@/store/entities/page-request'
    import ContainerTypeForm from './containertype-form.vue'
    import SortingModel from "@/store/entities/sortingmodel";

    class PageContainerTypeRequest extends PageRequest {
        keyword: string;
    }

    @Component({
        components: {ContainerTypeForm}
    })
    export default class ContainerType extends AbpBase {
        edit() {
            this.editModalShow = true;
        }

        //filters
        pagerequest: PageContainerTypeRequest = new PageContainerTypeRequest();

        createModalShow: boolean = false;
        editModalShow: boolean = false;

        get list() {
            return this.$store.state.containertype.list;
        };

        get loading() {
            return this.$store.state.containertype.loading;
        }

        async handleUpload(file) {
            let formData = new FormData();
            formData.append('file', file);
            await this.$store.dispatch({
                type: 'containertype/uploadFile',
                data: formData
            })
            await this.getpage();
        }

        create() {
            this.createModalShow = true;
        }

        async pageChange(page: number) {
            this.$store.commit('containertype/setCurrentPage', page);
            await this.getpage();
        }

        async search() {
            await this.pageChange(1);
        }

        pagesizeChange(pagesize: number) {
            this.$store.commit('containertype/setPageSize', pagesize);
            this.getpage();
        }

        async changeSort(data) {
            if (this.pagerequest.sorting == null) {
                this.pagerequest.sorting = [];
            }
            let item = this.pagerequest.sorting.find(x=>x.fieldName === data.key)
            if (item == null){
                let sortingModel = new SortingModel();
                sortingModel.fieldName = data.key;
                sortingModel.isDescendingDirection = data.order === "desc";
                this.pagerequest.sorting.push(sortingModel)
            }
            else{
                item.isDescendingDirection = data.order === "desc";
            }
            await this.getpage()
        }

        async getpage() {

            this.pagerequest.maxResultCount = this.pageSize;
            this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

            await this.$store.dispatch({
                type: 'containertype/getAll',
                data: this.pagerequest
            })
        }

        get pageSize() {
            return this.$store.state.containertype.pageSize;
        }

        get totalCount() {
            return this.$store.state.containertype.totalCount;
        }

        get currentPage() {
            return this.$store.state.containertype.currentPage;
        }

        columns = [{
            title: this.L('Name'),
            key: 'name',
            sortable: 'custom'
        }, {
            title: this.L('Code'),
            key: 'code',
            sortable: 'custom'
        }, {
            title: this.L('Size'),
            key: 'size',
            sortable: 'custom'
        }, {
            title: this.L('Actions'),
            key: 'Actions',
            width: 150,
            render: (h: any, params: any) => {
                return h('div', [
                    h('Button', {
                        props: {
                            type: 'primary',
                            size: 'small',
                            disabled: !this.hasEditPermission()
                        },
                        style: {
                            marginRight: '5px'
                        },
                        on: {
                            click: async () => {
                                this.$store.commit('containertype/edit', await this.$store.dispatch({
                                    type: 'containertype/get',
                                    data: params.row.id
                                }));
                                this.edit();
                            }
                        }
                    }, this.L('Edit')),
                    h('Button', {
                        props: {
                            type: 'error',
                            size: 'small',
                            disabled: !this.hasDeletePermission()
                        },
                        on: {
                            click: async () => {
                                this.$Modal.confirm({
                                    title: this.L('Tips'),
                                    content: this.L('Delete Container type Confirm'),
                                    okText: this.L('Yes'),
                                    cancelText: this.L('No'),
                                    onOk: async () => {
                                        await this.$store.dispatch({
                                            type: 'containertype/delete',
                                            data: params.row
                                        })
                                        await this.getpage();
                                    }
                                })
                            }
                        }
                    }, this.L('Delete'))
                ])
            }
        }]

        async created() {
            await this.getpage();
        }

        hasAddPermission(){
            return this.hasPermission("Pages.ContainerTypes.Add");
        }

        hasEditPermission(){
            return this.hasPermission("Pages.ContainerTypes.Edit");
        }

        hasDeletePermission(){
            return this.hasPermission("Pages.ContainerTypes.Delete");
        }
    }
