
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import Util from '../../../../lib/util'
    import AbpBase from '../../../../lib/abpbase'
    import ContainerType from '../../../../store/entities/containercontext/containertype'

    @Component
    export default class EditContainerType extends AbpBase {
        @Prop({type: Boolean, default: false}) value: boolean;
        @Prop({type: Boolean, default: false}) public toEdit: boolean;
        containerType: ContainerType = new ContainerType();
        containerSizes: any;

        data() {
            return {
                containerSizes: []
            }
        }

        async mounted() {
            this.containerSizes = await this.$store.dispatch('containersize/lookUp');
        }

        created() {
        }


        save() {
            (this.$refs.containerTypeForm as any).validate(async (valid: boolean) => {
                if (valid) {
                    await this.$store.dispatch({
                        type: 'containertype/update',
                        data: this.containerType
                    });
                    (this.$refs.containerTypeForm as any).resetFields();
                    this.$emit('save-success');
                    this.$emit('input', false);
                }
            })
        }

        cancel() {
            (this.$refs.containerTypeForm as any).resetFields();
            this.$emit('input', false);
        }

        visibleChange(value: boolean) {
            if (!value) {
                this.$emit('input', value);
            } else {
                if (this.toEdit){
                    this.containerType = Util.extend(true, {}, this.$store.state.containertype.editContainerType);
                }
                else {
                    this.containerType = new ContainerType();
                }
            }
        }

        containerTypeRule = {
            name: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Name')), trigger: 'blur'}],
            code: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Code')), trigger: 'blur'}]
        }
    }
